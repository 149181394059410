
import { defaultChainId, NODE_RPC, UINT_MAX } from "../configs";
import Web3 from "web3";
import erc20ContractAbi from '../constant/abi/erc20.abi.json';
import { ACTION_STATUS } from "../constants";
import BigNumber from "bignumber.js";
import {getGasPrice} from './useSwapContract'

export const getInfoToken = async (contractAddress)=>{
    const web3 = new Web3(NODE_RPC[defaultChainId]);
    const contract = new web3.eth.Contract(erc20ContractAbi, contractAddress);
    const symbol = await contract.methods.symbol().call();
    const decimal = await contract.methods.decimals().call();
    return {
        symbol,
         decimal: parseInt(decimal)
    }
}

export const approveToken = async ({provider, tokenContractAddress, contractAddress, account, network }, callback )=>{
   
    const web3 = new Web3(provider);
    const tokenContract = new web3.eth.Contract(erc20ContractAbi, tokenContractAddress);

    const gasPrice = await getGasPrice(provider, network);
  
    const amountInHex = "0x" + new BigNumber(UINT_MAX).toString(16);
    let sendObject = {};
    
      sendObject = { from: account, gasPrice };
    
    const method = tokenContract.methods.approve(contractAddress, amountInHex)
  
        callback({
          status: ACTION_STATUS.APPROVING,
        });  

        try {
        
         return method.send( sendObject )
          .on('error', (error) => {
            console.log(error);
            callback({
              status: ACTION_STATUS.APPROVE_FAILS,
            });
          })
          .then((receipt) => {
            if (receipt.status === true) {
              callback({
                status: ACTION_STATUS.APPROVED,
              });
            } else callback({ status: ACTION_STATUS.APPROVE_FAILS });
          });
          
        } catch (error) {
          console.log(error);
          callback({ status: ACTION_STATUS.APPROVE_FAILS });
        }
         
 
} 