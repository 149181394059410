import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useAuth from '../hook/useAuth';
import { ACTION_CONST, connectors, connectorLocalStorageKey } from '../constants';
import { useActiveWeb3React } from '../hook';
import { Modal, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { get } from "lodash";

const ConnectWalletModal = () => {
  
    const dispatch = useDispatch();
    const showConnectWalletModal = useSelector(state => state.wallet.showConnectWalletModal);
    const { login, logout } = useAuth();
    const { account, library, error, chainId } = useActiveWeb3React();


    const network = useSelector((state) => get(state, "utils.network", ''))

    const handleConnectClick = useCallback((walletConfig) => {
        try {
     
            login(walletConfig.connectorId);
            window.localStorage.setItem(connectorLocalStorageKey, walletConfig.connectorId);
            handleCloseModal();
        } catch (error) {
            console.log(error);
        }
    }, [])

    useEffect(() => {
        if (network == 'bsc' && chainId) {

            if (chainId === 56) {
                dispatch({
                    type: ACTION_CONST.CONNECT_WALLET_SUCCESS
                });
            } 
            
            else {
                dispatch({
                    type: ACTION_CONST.CONNECT_WALLET_FAIL
                });
                //show message connect to Velas Chain
                toast.warn('You need connect to Binance Smart Chain network!');
            }

        }
        if (network == 'vlx' && chainId) {
     
            if (chainId == 106) {
                dispatch({
                    type: ACTION_CONST.CONNECT_WALLET_SUCCESS
                });
            } else {

                dispatch({
                    type: ACTION_CONST.CONNECT_WALLET_FAIL
                })
                //show message connect to Velas Chain
                toast.warn('You need connect to Velas Chain network!');
            }
        }
        if (network == 'eth' && chainId) {
     
            if (chainId == 1) {
                dispatch({
                    type: ACTION_CONST.CONNECT_WALLET_SUCCESS
                });
            } else {

                dispatch({
                    type: ACTION_CONST.CONNECT_WALLET_FAIL
                })
                //show message connect to Velas Chain
                toast.warn('You need connect to Ethereum network!');
          
            }
        }

        if (network == 'polygon' && chainId) {
     
            if (chainId == 137) {
                dispatch({
                    type: ACTION_CONST.CONNECT_WALLET_SUCCESS
                });
            } else {

                dispatch({
                    type: ACTION_CONST.CONNECT_WALLET_FAIL
                })
                //show message connect to Velas Chain
                toast.warn('You need connect to Polygon network!');
          
            }
        }
        
        
    }, [network, chainId])

    // useEffect(() => {
    //     if (error)
    //         return;
    //     if (account && library && library.provider) {

    //         const web3 = new Web3Helper(library.provider, account, chainId)
    //         dispatch({
    //             type: ACTION_CONST.ENABLE_WALLET_SUCCESS,
    //             data: web3
    //         });
    //         handleCloseModal();
    //         return;
    //     }

    // }, [library, account, error])

const handleCloseModal = () => {
    dispatch({
        type: ACTION_CONST.HIDE_CONNECT_WALLET_MODAL
    });
}


    return (
        <>
            <Modal show={showConnectWalletModal} centered={true} size="sm" onHide={handleCloseModal} id="connectWalletModal" tabIndex="-1" aria-labelledby="connectWalletModalLabel" aria-hidden="true">
                <Modal.Header closeButton>
                    <Modal.Title>Connect to wallet</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {connectors.map((entry, key) => {
                        if (entry.enable === true) {
                            return (
                                <Button type="button" size="lg" variant="light" key={key} onClick={() => handleConnectClick(entry)} id={`wallet-connect-${entry.title.toLocaleLowerCase()}`}
                                    className="w-100 mb-2 btn-select-network"
                                >
                                    <img src={entry.icon} width="30px" alt="bscpad" />
                                    <div>{entry.title}</div>
                                </Button>
                            )
                        }
                    })}
                </Modal.Body>
            </Modal>
        </>
    );
}
export default ConnectWalletModal;


