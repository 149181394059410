import { ACTION_CONST, STATE } from "../../constants";


const initialState = {
  statusRequest: "none",
  message: "",
  latestBlock: 0,
  transactionHash: "",
  network: '',
  connectedWallet: false
};
export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_CONST.REQUEST_SUBMIT:
      return {
        ...state,
        statusRequest: STATE.SUBMIT,
      };
    case ACTION_CONST.REQUEST_DONE:
      return {
        ...state,
        statusRequest: "none",
      };

    case ACTION_CONST.SET_LATEST_BLOCK:
      return {
        ...state,
        latestBlock: action.data,
      };

    case ACTION_CONST.REQUEST_SUCCESS:
      return {
        ...state,
        statusRequest: STATE.SUCCESS,
      };
    case ACTION_CONST.REQUEST_FAIL:
      return {
        ...state,
        statusRequest: STATE.ERROR,
      };
    case ACTION_CONST.SET_MESSAGE:
      return {
        ...state,
        message: action.data,
      };

    case ACTION_CONST.SET_SHOW_TRANSACTION_HASH_MODAL:
      return {
        ...state,
        transactionHash: action.data,
      };
    case ACTION_CONST.NETWORK_CONNECTOR:
      return {
        ...state,
        network: action.data,
      };
      case ACTION_CONST.CONNECT_WALLET_SUCCESS:
        return {
          ...state,
          connectedWallet: true,
        };
        case ACTION_CONST.CONNECT_WALLET_FAIL:
          return {
            ...state,
            connectedWallet: false,
          };
    default:
      return state;
  }
};
