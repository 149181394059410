import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ACTION_CONST, ACTION_STATUS } from "../constants";
import { useActiveWeb3React } from "../hook";
import ConnectWalletModal from "../modals/ConnectWalletModal";
import WalletModal from "../modals/WalletModal";
import { Container, Row, Col } from 'react-bootstrap';
import { useLocation, useParams } from "react-router-dom";
import { helpers } from "../utils/helpers";
import { useLatestBlockNumber } from "../hook/useState";
import { get } from "lodash";
import { getSwapInfo, getSwapLabel, isContract, isPauseContract, swapToken } from "../utils/useSwapContract";
import { NODE_RPC } from "../configs";
import BigNumber from "bignumber.js";
import { approveToken } from "../utils/erc20ContractHelper";
import { toast } from "react-toastify";
import useDebounce from "../hook/useDebounce";
import Header from "../shared/Header";
import {version} from '../../package.json'

const HomePage = () => {
    const dispatch = useDispatch();
    const { account, library } = useActiveWeb3React();
    const { contract } = useParams();
    const location = useLocation();

    const [contractAddress, setContractAddress] = useState("");
    const [tInAmount, setInAmount] = useState(0);

    const [swapInfo, setSwapInfo] = useState({});
    const [labelSwap, setLabelSwap] = useState("");

    const [enableSwapBtn, setEnableSwapBtn] = useState(false)

    const [showError, setShowError] = useState(false);
    const [messageConnect, setMessageConnect] = useState("")
    const [isPauseSwap, setIsPauSwap] = useState(false);

    const network = useSelector((state) => get(state, "utils.network", ''))
    const blockNumber = useLatestBlockNumber();


    const handleOpenConnectWalletModal = () => {
        dispatch({
            type: ACTION_CONST.SHOW_CONNECT_WALLET_MODAL
        });
    }

    //check is contract Address
    const isContractAddress = useCallback(async (address, chain) => {

        const check = await isContract(address, chain);

        if (check) {   //todo
            setContractAddress(address)
        } else {
            setShowError(true);
        }

    }, [network]);

    const connectedWallet = useSelector((state) => get(state, "utils.connectedWallet", false))

    // check params
    useEffect(() => {

        const { pathname } = location;

        let contract = pathname.replaceAll(`/`, "");

        if (pathname.indexOf('velas') !== -1) {
            // setNetwork('vlx');
            dispatch({
                type: ACTION_CONST.NETWORK_CONNECTOR,
                data: 'vlx'
            })
            contract = contract.replace(`velas`, "")
            isContractAddress(contract, 106)
        } else if (pathname.indexOf('eth') !== -1) {

            dispatch({
                type: ACTION_CONST.NETWORK_CONNECTOR,
                data: 'eth'
            })
            contract = contract.replace(`eth`, "")
            isContractAddress(contract, 1)
        }
        else if (pathname.indexOf('polygon') !== -1) {

            dispatch({
                type: ACTION_CONST.NETWORK_CONNECTOR,
                data: 'polygon'
            })
            contract = contract.replace(`polygon`, "")
            isContractAddress(contract, 137)
        }
        else {
            isContractAddress(contract, 56);
            dispatch({
                type: ACTION_CONST.NETWORK_CONNECTOR,
                data: 'bsc'
            })
        }

    }, [location]);


    const debouncedState = useDebounce(tInAmount, 2000)
    //get Swap Info
    useEffect(() => {

        if (contractAddress && network && account && connectedWallet) {

            const chain = network === 'bsc' ? 56 : (network === 'vlx' ? 106 : (network === 'eth' ? 1 : (network == 'polygon' ? 137 : null)))


            const amountInTemp = BigNumber(tInAmount).multipliedBy(10 ** get(swapInfo, "tInDecimals", 18)).integerValue();
            const amountInHex = "0x" + amountInTemp.toString(16);
            // debugger
            if (chain && debouncedState) {
                getSwapInfo(NODE_RPC[chain], contractAddress, account, amountInHex).then(data => {
                    // console.log("swap info==>", data);
                    setSwapInfo(data)
                })

            } else {
                getSwapInfo(NODE_RPC[chain], contractAddress, account, 0).then(data => {
                    // console.log("swap info==>", data);
                    setSwapInfo(data)
                })
            }
        }

    }, [contractAddress, network, debouncedState, account, connectedWallet, blockNumber])

    useEffect(() => {
        if (contractAddress && network) {
            const chain = network === 'bsc' ? 56 : (network === 'vlx' ? 106 : (network === 'eth' ? 1 : (network == 'polygon' ? 137 : null)))
            if (chain) {
                getSwapLabel(chain, contractAddress).then(data => {
                    // console.log("data",data);
                    setLabelSwap(data)
                })

                isPauseContract(chain, contractAddress).then(r => {
                    // console.log("isPause Contract==>", r);
                    setIsPauSwap(r)
                })
            }
        }
    }, [contractAddress, network, connectedWallet, blockNumber])

    //check show message connect 
    useEffect(() => {
        switch (network) {
            case "bsc":
                setMessageConnect("Please connect wallet on Binance Smart Chain to swap.")
                break;
            case "vlx":
                setMessageConnect("Please connect wallet on Velas Chain to swap.")
                break;
            case "eth":
                setMessageConnect("Please connect wallet on Ethereum network to swap.")
                break;
            case "polygon":
                setMessageConnect("Please connect wallet on Polygon network to swap.")
                break;
            default:
                break;
        }
    }, [network]);


    const handleInputSwap = (e) => {
        if (helpers.isFloatFormatted(e.target.value, 4)) {

            setInAmount(e.target.value);
            if (BigNumber(e.target.value) > (BigNumber(get(swapInfo, "tInBalance", 0) / 10 ** get(swapInfo, "tInDecimals", 18)))) {
                setEnableSwapBtn(true);
            } else {
                setEnableSwapBtn(false);
            }
        }
    }

    const handleMaxButtonClick = () => {
        setInAmount(helpers.formatNumberDownRoundWithExtractMax(get(swapInfo, "tInBalance", 0) / 10 ** get(swapInfo, "tInDecimals", 18), 4));
        setEnableSwapBtn(false);
    }

    const handleApprove = () => {
        approveToken({ provider: library.provider, tokenContractAddress: get(swapInfo, "tIn", ''), contractAddress: contract, account }, result => {
            // console.log("result",result);
            dispatch({
                type: ACTION_CONST.REQUEST_SUBMIT
            })
            if (result.status === ACTION_STATUS.APPROVED) {
                setTimeout(() => {
                    dispatch({ type: ACTION_CONST.REQUEST_DONE })
                    toast.success('Approved Successfully');
                }, 3000)
            }
            if (result.status === ACTION_STATUS.APPROVE_FAILS) {
                dispatch({ type: ACTION_CONST.REQUEST_DONE })
                toast.error('Failed to Approve Tokens');
            }
        }).catch(err => {
            console.log("err==>", err);
            dispatch({ type: ACTION_CONST.REQUEST_DONE })
            toast.error('Failed to Approve Tokens. Please try again!');
        })
    }

    const handleSwapToken = () => {
        swapToken({ provider: library.provider, network , contractAddress: contract, wallet: account, amount: BigNumber(tInAmount).multipliedBy(10 ** get(swapInfo, "tInDecimals", 18)).integerValue() }, result => {
            // console.log("result",result);
            dispatch({
                type: ACTION_CONST.REQUEST_SUBMIT
            })
            if (result.status === ACTION_STATUS.SWAP_TOKEN_SUCCESS) {
                dispatch({ type: ACTION_CONST.REQUEST_DONE })
                toast.success('Swapped Successfully');
            }
            if (result.status === ACTION_STATUS.SWAP_TOKEN_FAIL) {
                dispatch({ type: ACTION_CONST.REQUEST_DONE })
                toast.error('Failed to Swap Tokens');
            }
        }).catch(err => {
            dispatch({ type: ACTION_CONST.REQUEST_DONE })
            toast.error('Failed to Swap Tokens. Please try again!');
        })
    }

    console.log("version:", version);

    return (
        <>
            <Header />
            <main>
                {showError ?
                    <Container>
                        <Row className="justify-content-center">
                            <Col xl="5" lg="10">
                                <div className="rd-card rd-card-error">
                                    <div className="rd-card-body p-5 w-auto mx-auto">
                                        <div className="rd-card-icon">
                                            <i className="fas fa-exclamation-triangle"></i>
                                        </div>
                                        <div className="rd-card-notice mb-4">
                                            Page does not exist
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    :
                    !connectedWallet ?

                        <Container>
                            <div className="rd-swap-card-d" style={{ marginBottom: '15px' }}>
                                <div className="rd-swap-card-title-d">{labelSwap}</div>
                            </div>
                            <div className="rd-swap-card-m" >
                                <Row className="justify-content-center">
                                    <Col xl="6" lg="10">
                                        <div className="rd-card rd-card-warning">
                                            <div className="rd-card-body ">
                                                <div className="rd-card-icon">
                                                    <i className="fas fa-exclamation-triangle"></i>
                                                </div>
                                                <div className="rd-card-notice mt-4 mb-4">
                                                    {
                                                        messageConnect
                                                    }
                                                </div>
                                                <button type="button" className="btn btn-primary d-flex mt-6 w-auto mx-auto" onClick={handleOpenConnectWalletModal}>
                                                    Connect wallet
                                                </button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                        :
                        <Container>
                            {
                                isPauseSwap &&
                                <div className="rd-swap-warning">
                                    <div className="rd-card-icon">
                                        <i className="fas fa-exclamation-triangle"></i>
                                    </div>
                                    <span>Token swapping is temporarily paused,<br />Please check again later.</span>
                                </div>
                            }

                            <div className="rd-swap-card">
                                <div className="rd-swap-card-inner">
                                    <div className="rd-swap-card-title mb-4">{labelSwap}</div>
                                    <div className="rd-swap-card-body">
                                        <div className="rd-swap-block">
                                            <div className="rd-swap-block-header">
                                                <div className="btn btn-link">
                                                    {/* <span className="icon">
                                              <img src="/images/coin-1.png" alt="" />
                                          </span> */}
                                                    <span>{get(swapInfo, "tInSymbol", '')}</span>
                                                </div>
                                                <label>Balance: {helpers.formatNumberDownRoundWithExtractMax(get(swapInfo, "tInBalance", 0) / 10 ** get(swapInfo, "tInDecimals", 18), 4)}</label>
                                            </div>
                                            <div className="rd-swap-block-input">
                                                <input type="text" className="form-control" placeholder="0.0" value={tInAmount} onChange={handleInputSwap} />
                                                <button type="button" onClick={handleMaxButtonClick} className="rd-btn-max">Max</button>
                                            </div>
                                        </div>
                                        <div className="rd-swap-switch">
                                            <button type="button" className="rb-btn-switch">
                                                <svg viewBox="0 0 24 24" className="sc-bdvvtL fYgbES icon-down" color="currentColor" width="20px" xmlns="http://www.w3.org/2000/svg"><path d="M11 5V16.17L6.11997 11.29C5.72997 10.9 5.08997 10.9 4.69997 11.29C4.30997 11.68 4.30997 12.31 4.69997 12.7L11.29 19.29C11.68 19.68 12.31 19.68 12.7 19.29L19.29 12.7C19.68 12.31 19.68 11.68 19.29 11.29C18.9 10.9 18.27 10.9 17.88 11.29L13 16.17V5C13 4.45 12.55 4 12 4C11.45 4 11 4.45 11 5Z" fill="currentColor"></path>
                                                </svg>
                                            </button>
                                        </div>
                                        <div className="rd-swap-block">
                                            <div className="rd-swap-block-header">

                                                <div className="btn btn-link">
                                                    {/* <span className="icon">
                                              <img src="/images/coin-2.jpg" alt="" />
                                          </span> */}
                                                    <span>{get(swapInfo, "tOutSymbol", '')}</span>
                                                </div>
                                            </div>
                                            <div className="rd-swap-block-input">
                                                <input type="text" className="form-control" placeholder={helpers.formatNumberDownRoundWithExtractMax(get(swapInfo, "tOutAmount", 0.0) / 10 ** get(swapInfo, "tOutDecimals", 18), 4)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="rd-swap-card-actions">
                                        <Row className="gx-xl-3">
                                            {!get(swapInfo, "hasAllowance", false) ?
                                                <Col>
                                                    <button type="button" onClick={handleApprove} className="btn btn-light w-100 text-nowrap">
                                                        Enable {get(swapInfo, "tInSymbol", '')}
                                                    </button>
                                                </Col>
                                                :
                                                <Col>
                                                    <button disabled={tInAmount == 0 || enableSwapBtn || !get(swapInfo, "hasAllowance", false) || get(swapInfo, "tOutAmount", 0.0) == 0} onClick={handleSwapToken} type="button" className="btn btn-light w-100">
                                                        Swap
                                                    </button>
                                                </Col>
                                            }
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </Container>
                }
            </main>
            <ConnectWalletModal />
            <WalletModal />
        </>
    );
};

export default HomePage;
