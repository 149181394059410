import { ACTION_CONST, connectorLocalStorageKey } from "../../constants";

const INITIAL_STATE = {
    walletInfo: {},
    showConnectWalletModal: false,
    showWalletModal: false,
};
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ACTION_CONST.SHOW_CONNECT_WALLET_MODAL:
            return {
                ...state,
                showConnectWalletModal: true,
            };
        case ACTION_CONST.HIDE_CONNECT_WALLET_MODAL:
            return {
                ...state,
                showConnectWalletModal: false,
            };
        case ACTION_CONST.SHOW_WALLET_MODAL:
            return {
                ...state,
                showWalletModal: true,
            };
        case ACTION_CONST.HIDE_WALLET_MODAL:
            return {
                ...state,
                showWalletModal: false,
            };
        default:
            return state;
    }
};