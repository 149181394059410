// import { BscConnector } from '@binance-chain/bsc-connector';
import { Web3Provider } from '@ethersproject/providers';
import { InjectedConnector } from '@web3-react/injected-connector';
// import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { ConnectorNames ,ETH_NETWORK_CHAIN_ID, NETWORK_URL} from '../constants';
import { chainSupport } from '../configs';
import { NetworkConnector } from './NetworkConnector';

if (typeof NETWORK_URL === 'undefined') {
  throw new Error('REACT_APP_NETWORK_URL must be a defined environment variable');
}

export const network = new NetworkConnector({
  urls: { [ETH_NETWORK_CHAIN_ID]: NETWORK_URL }, 
  defaultChainId : ETH_NETWORK_CHAIN_ID
});

console.log("NetworkConnector==>", network);
let networkLibrary;
export function getNetworkLibrary() {
  // eslint-disable-next-line no-return-assign
  return (networkLibrary = networkLibrary ?? new Web3Provider(network.provider));
}

export const injected = new InjectedConnector({
  supportedChainIds: chainSupport,
});



// mainnet only
// export const walletConnect = new WalletConnectConnector({
//   rpc: { [ETH_NETWORK_CHAIN_ID]: NETWORK_URL },
//   qrcode: true,
//   pollingInterval: 15000,
// });


export const connectorsByName = {
  [ConnectorNames.Injected]: injected,
  // [ConnectorNames.BSC]: bscConnector,
  // [ConnectorNames.WalletConnect]: walletConnect,
};
