import { useCallback } from 'react';
import { NoBscProviderError } from '@binance-chain/bsc-connector';
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';
import { NoEthereumProviderError, UserRejectedRequestError as UserRejectedRequestErrorInjected, } from '@web3-react/injected-connector';
import { injected, } from '../connectors';
import { connectorLocalStorageKey, ConnectorNames } from '../constants';
import { toast } from 'react-toastify';

function useAuth() {
  const { activate, deactivate } = useWeb3React();
  const connectorsByName = {
    [ConnectorNames.Injected]: injected,
    // [ConnectorNames.BSC]: bscConnector,
    // [ConnectorNames.WalletConnect]: walletConnect,
  };

  const login = useCallback((connectorID) => {
    const connector = connectorsByName[connectorID];
    if (connector) {
      try {
        activate(connector, async (error) => {
          window.localStorage.removeItem(connectorLocalStorageKey);
          if (error instanceof UnsupportedChainIdError) {
            toast.warn('Wrong network!');
          } else if (
            error instanceof NoEthereumProviderError ||
            error instanceof NoBscProviderError
          ) {
            toast.error('No provider was found');

          } else if (error instanceof UserRejectedRequestErrorInjected) {
            toast.error('Please authorize to access your account');
          } else {
            toast.error(error.message);
            setTimeout(() => {
              window.location.reload()
            }, 2000)
          }
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      toast.error('The connector config is wrong')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { login, logout: deactivate };
}

export default useAuth;
