export const POOL_INTERVAL = 5000;

export const EXPLORER = {
    // 1: "https://etherscan.io",
    // 3: "https://ropsten.etherscan.io",
    56: "https://bscscan.com",
    97: "https://testnet.bscscan.com",
    137:'https://explorer.matic.network'
};


export const NODE_RPC = {
    // 3: "https://ropsten.infura.io/v3/f2473914890349138c8b03e3ef79d165",
    56: "https://bsc-dataseed1.defibit.io/",
    97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    137: 'https://polygon-rpc.com/'
};




export const defaultChainId = 97;

export const chainSupport = [ 137];

export const UINT_MAX = '115792089237316195423570985008687907853269984665640564039457584007913129'