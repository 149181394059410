import { defaultChainId, NODE_RPC } from "./configs";
import { isMetamaskAvailable, isTrustWalletAvailable } from "./utils/utils";

export const connectorLocalStorageKey = 'connectorId';
export const ConnectorNames = {
    Injected: 'injected',
    // BSC : 'bsc',
    WalletConnect: 'WalletConnect',
}
export const connectors = [{
        title: 'Metamask',
        icon: '/images/metamask.svg',
        connectorId: ConnectorNames.Injected,
        enable: isMetamaskAvailable()
    },
    {
        title: 'TrustWallet',
        icon: '/images/trust.svg',
        connectorId: ConnectorNames.Injected,
        enable: isTrustWalletAvailable()
    },
    // {
    //     title: "WalletConnect",
    //     icon: '/images/WalletConnect.svg',
    //     connectorId: ConnectorNames.WalletConnect,
    //     enable: true
    // }
];
export const NetworkContextName = 'NETWORK';
export const ETH_NETWORK_CHAIN_ID = defaultChainId;
export const NETWORK_URL = NODE_RPC[defaultChainId];
export const ROUTES = {
    HOMEPAGE: "/:contract",
    VELAS: "/velas/:contract",
    POLYGON:'/polygon/:contract',
    ETH:'/eth/:contract',
    ERROR: '/'
};
export const ACTION_CONST = {
    SHOW_CONNECT_WALLET_MODAL: "SHOW_CONNECT_WALLET_MODAL",
    HIDE_CONNECT_WALLET_MODAL: "HIDE_CONNECT_WALLET_MODAL",
    SHOW_WALLET_MODAL: "SHOW_WALLET_MODAL",
    HIDE_WALLET_MODAL: "HIDE_WALLET_MODAL",
    SET_LATEST_BLOCK: "SET_LATEST_BLOCK",
    GET_INFO_WALLET: "GET_INFO_WALLET",
    ENABLE_WALLET_SUCCESS: "ENABLE_WALLET_SUCCESS",
    LOG_OUT_WALLET_SUCCESS: "LOG_OUT_WALLET_SUCCESS",
    SET_SHOW_TRANSACTION_HASH_MODAL: 'SET_SHOW_TRANSACTION_HASH_MODAL',
    SET_MESSAGE: 'SET_MESSAGE',
    REQUEST_FAIL: 'REQUEST_FAIL',
    REQUEST_SUCCESS:'REQUEST_SUCCESS',
    REQUEST_DONE: 'REQUEST_DONE',
    REQUEST_SUBMIT: 'REQUEST_SUBMIT',


    NETWORK_CONNECTOR: 'NETWORK_CONNECTOR',
    CONNECT_WALLET_SUCCESS: 'CONNECT_WALLET_SUCCESS',
    CONNECT_WALLET_FAIL: 'CONNECT_WALLET_FAIL' 

}
export const ACTION_STATUS = {
    APPROVING: "APPROVING",
    APPROVED: "APPROVED",
    APPROVE_FAILS: "APPROVE_FAILS",
    CLAIM_FAIL: "CLAIM_FAIL",
    CLAIM_SUCCESS: "CLAIM_SUCCESS",
    SWAP_TOKEN_SUBMIT:' SWAP_TOKEN_SUBMIT',
    SWAP_TOKEN_FAIL:' SWAP_TOKEN_FAIL',
    SWAP_TOKEN_SUCCESS:' SWAP_TOKEN_SUCCESS'
}
export const STATE = {
    NONE: "none",
    SUCCESS: 'success',
    ERROR: 'error',
    SUBMIT: 'submit'
}


export const MESSAGE = {
    CLAIM_FAILS:'Claim token fails!',
    ESTIMATE_GAS_FAILS:"ESTIMATE_GAS_FAILS"
}