import React from 'react'
import { Modal } from 'react-bootstrap'
import ConfirmationPendingContent from './ConfirmationPendingContent'
import TransactionSubmittedContent from './TransactionSubmittedContent'

const TransactionConfirmationModal = ({
  isOpen,
  onDismiss,
  attemptingTxn,
  hash,
  message,
  pendingText,
  children
}) => {


  return (
    <Modal centered show={isOpen} onHide={onDismiss}>
      {attemptingTxn ? (
        <ConfirmationPendingContent onDismiss={onDismiss} pendingText={pendingText} />
      ) : (hash || message)? (
        <TransactionSubmittedContent hash={hash} message={message} onDismiss={onDismiss} />
      ) : children}
    </Modal>
  )
}

export default TransactionConfirmationModal
