import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { ACTION_CONST } from '../constants';
import { useActiveWeb3React } from '../hook';
import { useErrorModal, useMessage, useSuccessModal, useTransactionHash, useWaitingModal } from '../hook/useState';
import TransactionConfirmationModal from '../modals/TransactionConfirmationModal/TransactionConfirmationModal';
import TransactionErrorContent from '../modals/TransactionConfirmationModal/TransactionErrorContent';
import { helpers } from '../utils/helpers';
import { Container} from 'react-bootstrap';
import { get } from "lodash";
import ConnectWalletModal from '../modals/ConnectWalletModal';
import WalletModal from '../modals/WalletModal';

export default function Header() {
    const dispatch = useDispatch();
    const { account } = useActiveWeb3React();
    const openWaitingModal = useWaitingModal()
    const openErrorModal = useErrorModal()
    const openSuccessModal = useSuccessModal();
    const message = useMessage();
    const textHash = useTransactionHash();
    const connectedWallet = useSelector((state) => get(state, "utils.connectedWallet", false))

    const handleOpenConnectWalletModal = () => {
        dispatch({
            type: ACTION_CONST.SHOW_CONNECT_WALLET_MODAL
        });
    }
    const handleOpenWalletModal = () => {
        dispatch({
            type: ACTION_CONST.SHOW_WALLET_MODAL
        });

    }
        //handle Clear state
        const clearState = () => {
            dispatch({ type: ACTION_CONST.REQUEST_DONE })
            dispatch({ type: ACTION_CONST.SET_MESSAGE, data: "" });
            dispatch({ type: ACTION_CONST.SET_SHOW_TRANSACTION_HASH_MODAL, data: "" });
        }
  return (
    <>
    <TransactionConfirmationModal isOpen={openWaitingModal} attemptingTxn={true} pendingText="" />
    <TransactionConfirmationModal isOpen={openSuccessModal} onDismiss={() => clearState()} hash={textHash} message={message} />
    <TransactionConfirmationModal isOpen={openErrorModal} onDismiss={() => clearState()}>
        <TransactionErrorContent message={message} onDismiss={() => clearState()} />
    </TransactionConfirmationModal>
    <header>
        <Container>
            <div className={`py-3 d-flex flex-wrap align-items-center justify-content-between`}>
                <a href="#" target="_blank">
                    <img className="d-none d-md-block" src="/images/logo.png" height="50" alt="Logo" />
                    <img className="d-md-none d-block" src="/images/logo-sm.png" height="50" alt="Logo" />
                </a>
                {connectedWallet ? <button type="button" className="btn btn-primary" onClick={handleOpenWalletModal}>
                    <span>{helpers.formatTransactionHash(account, 5, 5)}</span>
                </button> : <button type="button" className="btn btn-primary" onClick={handleOpenConnectWalletModal}>
                    Connect wallet
                </button>
                }
            </div>
        </Container>
    </header>
    <ConnectWalletModal />
    <WalletModal />
    </>
  )
}
