import React from 'react';
import { Button } from 'react-bootstrap';
import { useWaitingModal } from '../../hook/useState';
const ContentHeader = ({ children, onDismiss }) => {

    const confirm = useWaitingModal()
    return <div className="modal-content-header">
        <h5>{children}</h5>
        {!confirm &&
            <Button type="button" className="cs-close mw-0" onClick={onDismiss} variant="link">
                <i className="fas fa-times text-dark"></i>
            </Button>
        }

    </div>
}
export default ContentHeader;