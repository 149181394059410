import React from 'react'
import ContentHeader from './ContentHeader'

const ConfirmationPendingContent = ({ onDismiss, pendingText }) => {
  return (
    <div className="modal-wrap">
      <div className="modal-section">
        <ContentHeader onDismiss={onDismiss}><span className="p-text">Waiting for confirmation</span></ContentHeader>
        <div className="confirm-icon">
          <img className="spinner" src="/images/loader.svg" alt="loader" width="90" height="90" />
        </div>
        <h3 className="text-center mb-2">{pendingText}</h3>
        <div className="text-center text-warning">Confirm this transaction in your wallet</div>
      </div>
    </div>
  )
}

export default ConfirmationPendingContent
