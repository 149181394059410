import { useWeb3React } from "@web3-react/core"
import { useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { POOL_INTERVAL } from "../configs"
import { ACTION_CONST } from "../constants"
import useDebounce from "./useDebounce"

export default function AppUpdater() {
    const dispatch = useDispatch()
    const { chainId, library } = useWeb3React()
    const [state, setState] = useState({
        chainId,
        blockNumber: null,
    })
    const blockNumberCallback = useCallback(
        (blockNumber) => {
            setState((s) => {
                if (chainId === s.chainId) {
                    if (typeof s.blockNumber !== 'number') return { chainId, blockNumber }
                    return { chainId, blockNumber: Math.max(blockNumber, s.blockNumber) }
                }
                return s
            })
        },
        [chainId, setState]
    )

    // // attach/detach listeners
    // useEffect(() => {
    //     if (!library || !chainId) return undefined

    //     setState({ chainId, blockNumber: null })
    //     library
    //         .getBlockNumber()
    //         .then(blockNumberCallback)
    //         .catch((error) => console.error(`Failed to get block number for chainId: ${chainId}`, error))

    //     library.on('block', blockNumberCallback)
    //     return () => {
    //         library.removeListener('block', blockNumberCallback)
    //     }
    // }, [dispatch, chainId, library, blockNumberCallback])

    // const debouncedState = useDebounce(state, 1000)

    // useEffect(() => {
    //     if (!debouncedState.chainId || !debouncedState.blockNumber) return
    //     dispatch({ type: ACTION_CONST.SET_LATEST_BLOCK, data: debouncedState.blockNumber })
    //     // dispatch(setLastBlockNumber(debouncedState.blockNumber))
    // }, [dispatch, debouncedState.blockNumber, debouncedState.chainId])

    useEffect(()=>{
        let n =0 ;
        setInterval(()=>{
            dispatch({ type: ACTION_CONST.SET_LATEST_BLOCK, data: n++}) 
        },POOL_INTERVAL)
    },[])
    //check staking balance and check pad token in BSC side
    return null
}
