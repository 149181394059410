import { defaultChainId, NODE_RPC } from "../configs";
import Web3 from "web3";
import swapContractAbi from '../constant/abi/TokenSwap.abi.json'
import { ACTION_STATUS, MESSAGE } from "../constants";
import axios from "axios";

export const isContract = async (contractAddress, chainId) => {
  try {
    if(!contractAddress)
      return false;
    
    const web3 = new Web3(NODE_RPC[chainId]);
    // console.log("node rpc==>", NODE_RPC[chainId])
    const code = await web3.eth.getCode(contractAddress);
 
    return code !== "0x";
  } catch (error) {
      console.log("error==>", error);
    return false;
  }
};

export const isPauseContract = async(chainId, contractAddress)=>{
  try {
    const isPause = await _useFunctionSwapContract(chainId, contractAddress, "paused")
    return isPause;
  } catch (error) {
      console.log("error==>", error);
     return ""
  }
}

export const getSwapLabel = async (chainId, contractAddress)=>{
  try {
    const label = await _useFunctionSwapContract(chainId, contractAddress, "label")
    return label;
  } catch (error) {
      console.log("error==>", error);
     return ""
  }
}
  
//get swap info
export const getSwapInfo = async (provider, contractAddress, wallet, amount) =>{
    try {
        const web3 = new Web3(provider);
        const swapContract = new web3.eth.Contract(swapContractAbi, contractAddress);
        const result = await swapContract.methods.swapInfo(wallet, amount).call()

        // console.log("result==>", result);
        return result;

      } catch (error) {
          console.log("error==>", error);
         return null
      }
}

export const swapToken = async ({provider, contractAddress, wallet, amount, network }, callback) =>{
    const web3 = new Web3(provider);
    const swapContract = new web3.eth.Contract(swapContractAbi, contractAddress);
    const amountInHex = "0x" + amount.toString(16);
    const method = swapContract.methods.swap(amountInHex)
    //call swap function: 
    callback({ status: ACTION_STATUS.SWAP_TOKEN_SUBMIT });

    const gasPrice = await getGasPrice(provider, network);


    try {
      await web3.eth.call({
        from: wallet,
        to: contractAddress,
        data: method.encodeABI()
      });
      method.send({ from: wallet, gasPrice })
      .on('error', (error) => {
        console.log(error);
        callback({
          status: ACTION_STATUS.SWAP_TOKEN_FAIL
        });
      })
      .then((receipt) => {
        if (receipt.status === true) {
          callback({
            status: ACTION_STATUS.SWAP_TOKEN_SUCCESS,
            data: receipt.transactionHash,
          });
        } else callback({ status: ACTION_STATUS.SWAP_TOKEN_FAIL });
      })
      .catch((err) => {
        console.log(err);
        callback({ status: ACTION_STATUS.SWAP_TOKEN_FAIL });
      });
        
    }catch (err) {
      const message = err.message;
  
     // console.log("message==>", typeof (message));
  
      if (err.data) {
      const result = err.data.startsWith("0x") ? err.data : `0x${err.data}`;
      const reason = web3.utils.toAscii(`0x${result.substr(138)}`);
      console.log(`Revert reason: ${reason}`);
      callback({
          status: ACTION_STATUS.SWAP_TOKEN_FAIL,
          message: reason
      });
      } else {
      callback({
          status: ACTION_STATUS.SWAP_TOKEN_FAIL,
          message: MESSAGE.ESTIMATE_GAS_FAILS
      });
      }  
  }
}





export const _useFunctionSwapContract =  async(chainId, contractAddress, fun)=>{

  const web3 = new Web3(NODE_RPC[chainId]);
  const swapContract = new web3.eth.Contract(swapContractAbi, contractAddress);
  return await swapContract.methods[fun]().call()
}




export const  getGasPrice = async(provider, network)=> {
  const web3 = new Web3(provider);
  const promises = [
      (async () => {
          const gasPrice = await web3.eth.getGasPrice();
          return web3.utils.fromWei(gasPrice, 'gwei');
      })(),
  ];

  if(network==='polygon'){
    promises.push(
      (async () => {
          try {
              const response = await axios.get('https://gasstation-mainnet.matic.network/v2');
              return response.data.fast.maxFee;
          } catch (error) {
              const lastGasPrice = localStorage.getItem('lastGasPrice');
              if (lastGasPrice) {
                  return lastGasPrice;
              } else {
                
                  const gasPrice = await web3.eth.getGasPrice();
                  return Math.ceil(Math.round(this.web3.utils.fromWei(gasPrice, 'gwei') || 0)) * 2;
              }
          }
      })()
  );
  }
  

  const result = await Promise.all(promises);
  // console.log(result);
  const fasterGasPrice = Math.ceil(Math.max(...result));

  localStorage.setItem('lastGasPrice', fasterGasPrice);

  console.log(`gas price: ${fasterGasPrice} gwei`);
  return web3.utils.toWei(`${fasterGasPrice}`, 'gwei');
}